.ft__hd {
    color: #fff;
    text-transform: uppercase;
    margin-bottom: 15px;
}

.ft__hd span {
    border-bottom: 2px solid #fff;
}

.ft li {
    font-weight: 400;
    font-size: 14px;
    margin-bottom: 5px;
}

.ft__text {
    color: #fff;
    font-weight: 400;
    font-size: 14px;
    text-decoration: none;
}

.ft__text:hover {
    color: #fff;
}

.ft__bg {
    background: #00445E;
}

.ft__logo {
    display: flex;
    justify-content: end;
}

.ft__logo img {
    width: 155px;
    height: 100px;
}

.ft__follow {
    font-size: 15px;
    line-height: 1.4;
    font-family: Montserrat;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    color: #b2d334;
    text-align: end;
    margin-top: 30px;
}

/*header top area styles*/

.active1 {
    height: 3rem;
    width: 100%;
    position: fixed;
    top: 0px;
    transition: 0.3s linear;
    display: flex;
    justify-content: stretch;
    align-items: center;
    background-color: #03684E;
    border-bottom: 1px solid rgba(0, 0, 0, .1);
    z-index: 40;
    box-shadow: 0 2px 5px -1px rgba(0, 0, 0, .08);
}

.hidden {
    height: 4rem;
    width: 100%;
    z-index: 40;
    border-bottom: 1px solid rgba(0, 0, 0, .1);
    box-shadow: 0 2px 5px -1px rgba(0, 0, 0, .08);
    position: fixed;
    top: -80px;
    transition: 0.3s linear;

}

.header__top {
    margin-bottom: 48px !important;
}

/* .ht-social {
    display: flex;
    justify-content: end;
  } */

.ht-email span {
    font-size: 15px;
    font-weight: 500;
    color: #fff;
}

.ht-social a {
    background: #fff;
    color: #515151;
    height: 30px;
    line-height: 30px;
    width: 30px;
    font-size: 15px;
    margin-left: 12px;
    text-align: center;
    display: inline-block;
    border-radius: 50%;
    border: 1px solid #03684E;
}

.ht-social .facebook:hover {
    background: #4267B2;
    color: #fff;
}

.ht-social .twitter:hover {
    background: #1DA1F2;
    color: #fff;
}

.ht-social .linkedin:hover {
    background: #0077B5;
    color: #fff;
}

.ht-social .whatsapp:hover {
    background: #25D366;
    color: #fff;
}

.ht-social .instagram:hover {
    background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
    color: #fff;
}

.ht-social .pinterest:hover {
    background: #E60023;
    color: #fff;
}

.contact__top ul {
    display: flex;
    text-align: start;
}

.contact__top ul li {
    line-height: 235%;
    padding-left: 15px;
    color: #fff;
}

.ftb__hd {
    color: #fff;
    font-size: 12px;
    line-height: 1.5;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    text-align: justify;
}

.ft__margin__top {
    margin-top: 40px;
}
.ftb__hd__nav  {
    display: flex;
    justify-content: end;
}
.ftb__hd__nav li {
    margin-left: 20px;
}
.ftb__hd__nav li .nav-link{
    color: #fff;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1.5px;
}
.ftb__hd__nav li .nav-link:hover{
    color: #fff !important;
}
.ft__copyright {
    font-size: 12px;
    color: #fff;
    text-align: end;
}

@media all and (max-width: 480px) {
    .header__top {
        margin-bottom: 70px !important;
    }

    .active1 {
        height: 70px;
    }

    .ht-email span {
        display: inline-block;
        font-size: 12px;
    }

    .ht-social a {
        height: 25px;
        line-height: 25px;
        width: 25px;
        font-size: 15px;
    }

    .ht-email {
        margin-bottom: 5px;
    }


}