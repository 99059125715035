@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;700&display=swap');

* {
  font-family: "Montserrat";
}

/* body {
  background: url('./images/drawingsclear.webp');
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-position: 100% -86px;
  background-color: rgba(0,0,0, 0.06) !important;
} */
.container,
.container-lg,
.container-md,
.container-sm,
.container-xl {
  max-width: 1235px !important;
}

.margin__top {
  margin-top: 70px;
}

.bg__body {
  background-color: #fff;
}

.banner__img img {
  height: 500px;
  width: 100%;
  /* opacity: 80%; */
}

.banner__text {
  left: 50%;
  padding-left: 15px;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(-85%, 60%);
  transform: translate(-50%, -50%);
}

.banner__title {
  font-weight: 700;
  text-align: center;
  font-size: 60px;
  line-height: 66px;
  color: #03AEEE
}

.banner__desc__sec {
  margin: 0px 80px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.banner__description {
  color: rgb(255, 255, 255);
  font-weight: 600;
  line-height: 25.2px;
  text-align: center;
}

.banner__btn {
  background: #00445E;
  color: #fff;
  font-size: 12px;
  font-weight: 600;
  padding: 8px 35px;
  line-height: 30px;
  text-decoration: none;
  border-radius: 5px;
  border: 1px solid #00445E;
  text-transform: uppercase;
}

.banner__btn:hover {
  background-color: rgba(255, 0, 0, 0);
  color: #00445E;
  font-size: 12px;
  font-weight: 600;
  padding: 8px 35px;
  line-height: 30px;
  text-decoration: none;
  border-radius: 5px;
  border: 1px solid #00445E;
  text-transform: uppercase;
  background-origin: border-box;
}



/* slick start  */
.slick__slider .slick-slider {
  background-color: #fff !important;
}

.slick__slider .slick-prev,
.slick__slider .slick-next {
  color: #5f5b5b !important;
  width: 18px;
  height: 18px;
  margin: 0px 50px;
  overflow: hidden;
  z-index: 2;
}

.slick__slider .slick-prev:before,
.slick-next:before {
  color: #5f5b5b !important;
  font-size: 22px !important;
}

/* slick end  */
/* .bg__cover {
  background: url('./images/drawingsclear.webp');
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-position: 100% -86px;
  background-color: rgba(25, 26, 25, 0.1)
} */

/* .bg__cover__overlay {
  background-color: rgba(0,0,0, 0.1)
} */

.lir__text {
  background: #00445E;
  color: #fff;
  padding: 2px 4px;
}

.ars__img img {
  width: 100%;
  height: 350px;
}

.text__subheader {
  font-size: 12px;
  font-weight: 600;
  line-height: 1.4;
  color: #03AEEE;
  text-transform: uppercase;
  letter-spacing: 1.5px;
}


.text__ars__color {
  color: rgb(38, 36, 76);
}

.txt__ars__header {
  font-size: 34px;
  font-weight: 600;
  letter-spacing: 1.5px;
  line-height: 40.8px;
  text-transform: uppercase;
}

/* .text__header {
  font-size: 22px;
  font-weight: 600;
  letter-spacing: 1.5px;
  line-height: 1.35;
  text-transform: uppercase;
} */

.text__description {
  font-size: 15px;
  font-weight: 400;
  line-height: 22.5px;
  color: rgb(38, 36, 76);
  text-align: justify;
}

.btn__button {
  background: #00445E;
  color: #fff;
  font-size: 12px;
  font-weight: 600;
  padding: 8px 35px;
  line-height: 35px;
  text-decoration: none;
  border-radius: 5px;
  border: 1px solid #00445E;
  text-transform: uppercase;
}

.btn__button:hover {
  background-color: rgba(255, 0, 0, 0);
  ;
  color: #00445E;
  font-size: 12px;
  font-weight: 600;
  padding: 8px 35px;
  line-height: 35px;
  text-decoration: none;
  border-radius: 5px;
  border: 1px solid #00445E;
  text-transform: uppercase;
  background-origin: border-box;
}

.safety {
  margin-top: 20px;
  position: relative;
  text-align: center;
  color: white;
}

.safety__img img {
  width: 100%;
  height: 400px;
}

.safety__text {
  left: 50%;
  /* padding-left: 15px; */
  position: absolute;
  top: 50%;
  -webkit-transform: translate(-85%, 60%);
  transform: translate(-87%, -50%);
}

.text__safety__color {
  color: #fff;
}

.txt__safety__header {
  font-size: 22px;
  font-weight: 600;
  letter-spacing: 3px;
  line-height: 1.35;
  text-transform: uppercase;
}

/* slider start  */
.world__partner__carousel {
  padding: 0 20px;
}

.world__partner__carousel .slick-prev:before,
.slick-next:before {
  color: #484848 !important;
}

.world__partner__carousel .slick-slider .slick-slide {
  padding: 0px 9px;
  align-items: center;
}

.world__partner__carousel .slick-slider,
.slick-list,
.slick-track {
  height: 100%;
}

.web__carousel__box__card .card {
  min-height: 120px !important;
  text-align: start;
  max-height: 100px !important;
  box-shadow: 0px 0px 11px 1px rgb(0 0 0 / 15%);
  border-radius: 20px;
  z-index: 1;
}



.web__carousel__box__card__discription {
  color: #000000;
  margin-top: 5px;
  margin-bottom: 14px;
  font-size: 15px;
  line-height: 145%;
  font-family: "Raleway", sans-serif;
  min-height: 120px !important;
  max-height: 130px !important;
  overflow: hidden;
}

/* slider end */



.key__industries__card {
  position: relative;
  width: 100%;
  min-height: 220px;
  max-height: 280px;
}

.key__industries__card__overlay {
  position: absolute;
  bottom: 0;
  background: rgb(0, 0, 0);
  background: rgba(0, 0, 0, 0.5);
  color: #f1f1f1;
  width: 100%;
  height: 100%;
  transition: .5s ease;
  opacity: 0;
  align-items: center;
  color: white;
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  display: flex;
  justify-content: center;
}

.key__industries__card:hover .key__industries__card__overlay {
  opacity: 1;
}

.key__industries__card:hover .key__industries__card__overlay a {
  color: #fff;
}

.key__industries__card__img img {
  width: 100%;
  height: 220px;
}

.key__industries__card__title {
  color: rgb(38, 36, 76);
  line-height: 120%;
  font-weight: 500;
  font-size: 15px;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  margin-bottom: 0.2rem !important;
}

.esm__img {
  margin-top: 100px;
}

.esm__img img {
  width: 100%;
  height: 350px;
}

.services__dept {
  margin-top: 20px;
  position: relative;
  text-align: center;
  color: white;
}

.services__dept__img img {
  width: 100%;
  height: 820px;
}

.services__dept__overlay {
  left: 50%;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(-85%, 60%);
  transform: translate(0%, -50%);
}

.services__dept__overlay .card {
  width: 90%;
  height: 620px;
}

.services__dept__overlay .services__dept__card_img img {
  width: 100%;
  height: 300px;
}

.text__services__dept__color {
  color: #fff;
}

.services__txt__subhd {
  margin-top: 15px;
  font-size: 13px;
  text-align: start;
  color: #a8a7b7;
  font-family: "Raleway";
  letter-spacing: normal;
}

.services__txt__hd {
  margin-top: 10px;
  color: #26244c;
  text-align: start;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 3px;
  line-height: 125%;
  text-transform: uppercase;
}


@media screen and (max-width: 768px) {
  .banner__img img {
    height: 200px;
    width: 100%;
  }



  .ars__img img {
    width: 100%;
    height: 300px;
  }

  .world__partner__carousel {
    padding: 0px 20px;
  }

  .world__partner__carousel .slick-dots li {
    width: 3px !important;
  }

  .web__carousel__box__card .card {
    min-height: 120px !important;
    max-height: 100px !important;
  }

  .web__carousel__box__card .slick-dots {
    bottom: -35px !important;
  }
}